@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;700&display=swap");
* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.container {
  width: 100%;
}
@media (max-width: 574px) {
  .container {
    margin: 0 15px;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

.erroForm {
  color: #F9A825;
}

#header {
  background: url(../imgs/banner.jpg) center right no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: right;
}
#header .container {
  display: flex;
  justify-content: flex-end;
}
#header .container .slogan {
  display: flex;
  flex-direction: column;
}
@media (max-width: 574px) {
  #header .container .slogan {
    width: 100%;
    align-items: center;
  }
}
@media (min-width: 576px) {
  #header .container .slogan {
    width: 100%;
    align-items: center;
  }
}
@media (min-width: 768px) {
  #header .container .slogan {
    max-width: 90%;
    align-items: flex-start;
  }
}
@media (min-width: 992px) {
  #header .container .slogan {
    max-width: 70%;
  }
}
@media (min-width: 1200px) {
  #header .container .slogan {
    max-width: 60%;
  }
}
@media (min-width: 1400px) {
  #header .container .slogan {
    max-width: 53%;
  }
}
@media (max-width: 574px) {
  #header .container .slogan img {
    max-width: 90%;
  }
}
@media (min-width: 576px) {
  #header .container .slogan img {
    max-width: 75%;
  }
}
@media (min-width: 768px) {
  #header .container .slogan img {
    max-width: 60%;
  }
}
#header .container .slogan h1 {
  font-weight: 400;
  color: #ffffff;
  padding-top: 25px;
}
@media (max-width: 574px) {
  #header .container .slogan h1 {
    font-size: 35px;
    text-shadow: 1px 1px 5px #383838;
    text-align: center;
  }
}
@media (min-width: 576px) {
  #header .container .slogan h1 {
    font-size: 35px;
    text-shadow: 1px 1px 5px #383838;
    text-align: center;
  }
}
@media (min-width: 768px) {
  #header .container .slogan h1 {
    font-size: 45px;
    text-align: left;
  }
}
#header .container .slogan h1 span {
  color: #46d400;
}

#SectionA {
  background: #fff;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
#SectionA .container {
  padding: 100px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
@media (max-width: 574px) {
  #SectionA .container {
    flex-direction: column;
    gap: 50px;
  }
}
@media (min-width: 576px) {
  #SectionA .container {
    flex-direction: column;
    gap: 50px;
  }
}
@media (min-width: 768px) {
  #SectionA .container {
    flex-direction: column;
    gap: 50px;
  }
}
@media (min-width: 992px) {
  #SectionA .container {
    flex-direction: row;
  }
}
#SectionA .container .text p {
  color: #213c54;
  font-size: 18px;
  line-height: 25px;
  padding: 10px 0;
}
@media (max-width: 574px) {
  #SectionA .container .text {
    max-width: 100%;
  }
}
@media (min-width: 576px) {
  #SectionA .container .text {
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  #SectionA .container .text {
    max-width: 35%;
  }
}
#SectionA .container .logos {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  /* .credLogo {

   }*/
}
#SectionA .container .logos .parceiros {
  width: 100%;
}

#sectionB {
  padding: 100px 0;
  background: url(../imgs/bg-2.jpg) center center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
}
#sectionB .container .title {
  font-weight: 400;
  font-size: 43px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  margin-bottom: 100px;
}
#sectionB .container .title strong {
  font-weight: 600;
  color: #fff;
}
#sectionB .container .cards {
  display: flex;
  justify-content: space-evenly;
}
@media (max-width: 574px) {
  #sectionB .container .cards {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
}
@media (min-width: 576px) {
  #sectionB .container .cards {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
}
@media (min-width: 768px) {
  #sectionB .container .cards {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
}
@media (min-width: 992px) {
  #sectionB .container .cards {
    flex-direction: row;
    align-items: flex-start;
  }
}
#sectionB .container .cards .card {
  width: 300px;
  text-align: center;
}
#sectionB .container .cards .card img {
  width: 137px;
}
#sectionB .container .cards .card h3 {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  margin-top: 25px;
}
#sectionB .container .cards .card h3 strong {
  font-weight: 600;
}

#sectionC {
  padding: 100px 0;
  background: #46d400;
  background-size: cover;
  display: flex;
  justify-content: center;
}
#sectionC .container {
  margin-right: 0;
  color: #fff;
}
#sectionC .container .title {
  font-size: 43px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 100px;
}
#sectionC .container .title strong {
  font-weight: 600;
}
#sectionC .container .cards {
  display: flex;
  justify-content: space-evenly;
}
@media (max-width: 574px) {
  #sectionC .container .cards {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
}
@media (min-width: 576px) {
  #sectionC .container .cards {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
}
@media (min-width: 768px) {
  #sectionC .container .cards {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
}
@media (min-width: 992px) {
  #sectionC .container .cards {
    flex-direction: row;
    align-items: flex-start;
  }
}
#sectionC .container .cards .card {
  width: 300px;
  text-align: center;
}
#sectionC .container .cards .card img {
  width: 137px;
}
#sectionC .container .cards .card h3 {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-top: 25px;
}
#sectionC .container .cards .card h3 strong {
  font-weight: 600;
}
#sectionC .container .rodape {
  padding-top: 80px;
}
#sectionC .container .rodape p {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

#sectionD {
  padding: 100px 0;
  background: url(../imgs/bg-3.jpg) fixed center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
}
#sectionD .container {
  display: flex;
  gap: 100px;
}
@media (max-width: 574px) {
  #sectionD .container {
    flex-direction: column;
  }
}
@media (min-width: 576px) {
  #sectionD .container {
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  #sectionD .container {
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  #sectionD .container {
    flex-direction: row;
  }
}
#sectionD .container .header {
  color: #fff;
}
#sectionD .container .header .titulo {
  display: flex;
  align-items: flex-start;
  text-align: left;
  gap: 40px;
  width: 100%;
  margin-bottom: 40px;
  flex-direction: column;
}
#sectionD .container .header .titulo h2 {
  font-size: 41px;
  font-weight: 400;
}
#sectionD .container .header .titulo h2 strong {
  font-weight: 600;
}
#sectionD .container .header .descricao p {
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 15px;
}
#sectionD .container .content {
  display: flex;
  width: 100%;
  gap: 25px;
}
@media (max-width: 574px) {
  #sectionD .container .content {
    flex-direction: column;
  }
}
@media (min-width: 576px) {
  #sectionD .container .content {
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  #sectionD .container .content {
    flex-direction: row;
  }
}
#sectionD .container .content .col {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
#sectionD .container .content .col h3 {
  color: #fff;
  margin-bottom: 15px;
  text-align: center;
  font-size: 20px;
}
#sectionD .container .content .col a.btn {
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
  background-color: #1f3a53;
  text-align: center;
  border-radius: 30px;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
}
#sectionD .container .content .col a.btn:hover {
  color: #212e3a;
  background-color: #ffffff;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
}
@media (max-width: 574px) {
  #sectionD .container .content .col a.btn {
    padding: 20px 100px;
  }
}
@media (min-width: 576px) {
  #sectionD .container .content .col a.btn {
    width: 100%;
    padding: 20px 15px;
  }
}
#sectionD .container .content .col .btn {
  cursor: pointer;
}

#sectionE {
  background: url(../imgs/bg-4.jpg) top center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
}
#sectionE .container {
  padding: 100px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}
#sectionE .container .title {
  font-weight: 400;
  font-size: 43px;
  text-align: center;
  color: #fff;
  margin-bottom: 80px;
}
#sectionE .container .title strong {
  font-weight: 600;
}
#sectionE .container .rowCard {
  max-width: 1100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
}
@media (max-width: 574px) {
  #sectionE .container .rowCard {
    flex-direction: column;
  }
}
@media (min-width: 576px) {
  #sectionE .container .rowCard {
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  #sectionE .container .rowCard {
    flex-direction: row;
  }
}
@media (max-width: 574px) {
  #sectionE .container .rowCard .texto {
    max-width: 100%;
  }
}
@media (min-width: 576px) {
  #sectionE .container .rowCard .texto {
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  #sectionE .container .rowCard .texto {
    max-width: 50%;
  }
}
#sectionE .container .rowCard .texto h3 {
  font-weight: 400;
  color: #ffffff;
}
@media (max-width: 574px) {
  #sectionE .container .rowCard .texto h3 {
    font-size: 33px;
  }
}
@media (min-width: 576px) {
  #sectionE .container .rowCard .texto h3 {
    font-size: 43px;
  }
}
#sectionE .container .rowCard .texto h3 strong {
  font-weight: 600;
}
#sectionE .container .rowCard .texto p {
  font-size: 25px;
  margin-top: 30px;
  color: #ffffff;
  font-weight: 400;
  line-height: 35px;
}
#sectionE .container .rowCard .texto p strong {
  color: #ffffff;
  font-weight: 600;
}
@media (max-width: 574px) {
  #sectionE .container .micInd,
#sectionE .container .peqEmp {
    flex-direction: column;
    text-align: center;
  }
}
@media (min-width: 576px) {
  #sectionE .container .micInd,
#sectionE .container .peqEmp {
    flex-direction: column;
    text-align: center;
  }
}
@media (min-width: 768px) {
  #sectionE .container .micInd,
#sectionE .container .peqEmp {
    flex-direction: column;
    text-align: center;
  }
}
@media (min-width: 992px) {
  #sectionE .container .micInd,
#sectionE .container .peqEmp {
    flex-direction: row-reverse;
    text-align: left;
  }
}
@media (max-width: 574px) {
  #sectionE .container .micEmp {
    text-align: center;
  }
}
@media (min-width: 576px) {
  #sectionE .container .micEmp {
    text-align: center;
  }
}
@media (min-width: 768px) {
  #sectionE .container .micEmp {
    text-align: center;
  }
}
@media (min-width: 992px) {
  #sectionE .container .micEmp {
    text-align: right;
  }
}
#sectionE .imagem img {
  max-width: 100%;
}
#sectionE .aviso {
  margin-top: 100px;
  background-color: #47bb00;
  font-size: 20px;
  line-height: 30px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 574px) {
  #sectionE .aviso {
    flex-direction: column;
    padding: 30px 25px;
    gap: 25px;
  }
}
@media (min-width: 576px) {
  #sectionE .aviso {
    flex-direction: column;
    padding: 30px 25px;
    gap: 25px;
  }
}
@media (min-width: 768px) {
  #sectionE .aviso {
    padding: 30px 100px;
    flex-direction: row;
    gap: 50px;
  }
}
#sectionE .aviso .imgAviso {
  text-align: right;
}
#sectionE .aviso .textoAviso {
  color: #ffffff;
}

#sectionF {
  display: flex;
  flex-direction: column;
  background: url(../imgs/bg-6.jpg) center center no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
@media (max-width: 574px) {
  #sectionF {
    background-position: left;
  }
}
@media (min-width: 576px) {
  #sectionF {
    background-position: left;
  }
}
#sectionF .containerF {
  width: 50vw;
  display: flex;
  flex-direction: column;
}
@media (max-width: 574px) {
  #sectionF .containerF {
    padding: 0 50px 0 50px;
    width: 100vw;
  }
}
@media (min-width: 576px) {
  #sectionF .containerF {
    padding: 50px 100px 50px 100px;
    width: 90vw;
  }
}
@media (min-width: 768px) {
  #sectionF .containerF {
    width: 70vw;
  }
}
@media (min-width: 992px) {
  #sectionF .containerF {
    width: 50vw;
  }
}
#sectionF .containerF .title {
  padding: 100px 0 0 0;
}
#sectionF .containerF .title h2 {
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  margin-bottom: 80px;
  font-size: 43px;
}
@media (max-width: 574px) {
  #sectionF .containerF .title h2 {
    font-size: 35px;
  }
}
#sectionF .containerF .title h2 strong {
  font-weight: 600;
  color: #ffffff;
}
#sectionF .containerF .texto .inscMunic {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
#sectionF .containerF .texto .inscMunic a {
  background-color: #025b49;
  color: #fff;
  text-decoration: none;
  padding: 5px 20px;
  border-radius: 10px;
  font-weight: 400;
  margin: 0 0 10px 10px;
  font-size: 20px;
}
#sectionF .containerF .texto .inscMunic a:hover {
  background-color: #013853;
}
#sectionF .containerF .texto .inscMunic p {
  margin: 0;
}
#sectionF .containerF .texto h2 {
  font-weight: 300;
  font-size: 43px;
  color: #fff;
  margin-bottom: 30px;
}
#sectionF .containerF .texto h2 strong {
  font-weight: 900;
}
#sectionF .containerF .texto h3 {
  color: #fff;
}
#sectionF .containerF .texto .tipoEmpresa {
  color: #ffffff;
  font-weight: 600;
}
#sectionF .containerF .texto .tipoEmpresa span {
  margin-bottom: 50px;
}
#sectionF .containerF .texto p {
  font-size: 20px;
  line-height: 25.5px;
  margin-bottom: 30px;
  font-weight: 400;
  color: #ffffff;
}
#sectionF .containerF .texto ul {
  margin-top: 20px;
  list-style-position: inside;
  padding-left: 0;
}
#sectionF .containerF .texto li {
  list-style: none;
  color: #ffffff;
  font-weight: 400;
}
#sectionF .containerF .texto .asterisco {
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
}
#sectionF .containerF .imagem {
  align-self: flex-end;
}
@media (max-width: 574px) {
  #sectionF .containerF .imagem {
    display: none;
  }
}
@media (min-width: 576px) {
  #sectionF .containerF .imagem {
    display: none;
  }
}
@media (min-width: 1200px) {
  #sectionF .containerF .imagem {
    display: block;
  }
}
#sectionF .containerF .imagem img {
  margin-bottom: -4px;
}

#sectionG {
  background: #47bb00;
  display: flex;
  justify-content: center;
}
#sectionG .container {
  padding: 100px 0;
}
#sectionG .container .title {
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  margin-bottom: 80px;
  font-size: 43px;
}
#sectionG .container .title strong {
  font-weight: 600;
  color: #ffffff;
}
#sectionG .container .duvida {
  padding: 40px;
  margin: 15px 0px;
  border: 2px solid #ffffff;
  border-radius: 90px;
  text-align: center;
  min-height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#sectionG .container .duvida h3 {
  font-size: 25px;
  color: #ffffff;
  font-weight: 600;
}
#sectionG .container .duvida p {
  font-size: 18px;
  color: #ffffff;
  font-weight: 400;
  margin-top: 15px;
}
#sectionG .container h4 {
  max-width: 1200px;
  margin: 0 auto;
  font-size: 22px;
  color: #ffffff;
  font-weight: 600;
  padding-top: 20px;
  text-align: center;
}

#footer {
  display: flex;
  justify-content: center;
}
#footer .container .logos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media (max-width: 574px) {
  #footer .container .logos img {
    max-width: 180px;
  }
}
@media (min-width: 576px) {
  #footer .container .logos img {
    max-width: 150px;
  }
}
@media (min-width: 768px) {
  #footer .container .logos img {
    max-width: 140px;
  }
}
@media (min-width: 992px) {
  #footer .container .logos img {
    max-width: 180px;
  }
}
@media (min-width: 1200px) {
  #footer .container .logos img {
    max-width: 200px;
  }
}

#sectionForm {
  background: url(../imgs/bg-4.jpg) top center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px;
}
@media (max-width: 574px) {
  #sectionForm .formHeader {
    display: flex;
    flex-direction: column;
    padding: 0 0 80px 0;
    align-items: center;
    width: 100%;
  }
  #sectionForm .formHeader h4 {
    text-align: center;
    padding: 25px 0 0 0;
  }
}
@media (min-width: 576px) {
  #sectionForm .formHeader {
    display: flex;
    flex-direction: column;
    padding: 0 0 80px 0;
    align-items: center;
    width: 100%;
  }
  #sectionForm .formHeader h4 {
    text-align: center;
    padding: 25px 0 0 0;
  }
}
@media (min-width: 768px) {
  #sectionForm .formHeader {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1320px;
    width: 100%;
  }
  #sectionForm .formHeader h4 {
    text-align: right;
  }
}
#sectionForm .formHeader .fHeaderTitle,
#sectionForm .formHeader h4 {
  font-weight: 300;
  color: #fff;
}
#sectionForm .formHeader .fHeaderTitle {
  font-size: 43px;
}
@media (max-width: 574px) {
  #sectionForm form {
    width: 350px;
  }
}
@media (min-width: 576px) {
  #sectionForm form {
    width: 540px;
  }
}
@media (min-width: 768px) {
  #sectionForm form {
    width: 720px;
  }
}
@media (min-width: 992px) {
  #sectionForm form {
    width: 960px;
  }
}
@media (min-width: 1200px) {
  #sectionForm form {
    width: 1140px;
  }
}
@media (min-width: 1400px) {
  #sectionForm form {
    width: 1320px;
  }
}
#sectionForm form .btnSend {
  text-align: center;
  padding: 50px 0 0 0;
}
#sectionForm form .btnSend :hover {
  background-color: #ffffff !important;
  color: #46d400;
}
#sectionForm form .btnSend Button {
  cursor: pointer;
  width: 75%;
  padding: 15px 0;
  border-radius: 50px;
  font-weight: 600;
  font-size: 20px;
  background-color: #46d400;
}
#sectionForm .fileUpload {
  margin-top: 50px;
}
#sectionForm .fileUpload h4 {
  padding: 10px 0 10px 30px;
  color: #fff;
}
#sectionForm .fileUpload p {
  padding: 10px 0 10px 30px;
  color: rgb(162, 162, 162);
}
#sectionForm .fileUpload .erroForm {
  margin-left: 30px;
}
#sectionForm .fileUpload .uploadAnexo {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
}
#sectionForm .fileUpload .uploadAnexo .campoStyle {
  width: 100%;
  padding: 15px;
  color: #46d400;
  font-size: 16px;
  font-weight: 700;
}
#sectionForm .grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -moz-column-gap: 10px;
       column-gap: 10px;
  row-gap: 20px;
}
#sectionForm .grid .personalColors {
  color: #fff;
}
#sectionForm .grid .campoStyle {
  background-color: rgb(255, 255, 255);
  max-height: 55px;
  border-radius: 50px;
  width: 100%;
}
#sectionForm .grid .campoStyle ::before,
#sectionForm .grid .campoStyle ::after {
  border: 0px;
}
#sectionForm .grid .campoStyle label {
  font-family: "Montserrat", sans-serif;
  color: rgb(0, 0, 0);
  font-weight: 500;
}
#sectionForm .grid .radioStyle {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  max-height: 80px;
}
#sectionForm .grid .radioStyle span:nth-child(1) {
  color: #46d400;
}
#sectionForm .grid .radioStyle label {
  color: #fff;
}
@media (max-width: 574px) {
  #sectionForm .grid {
    width: inherit;
  }
  #sectionForm .grid .Empresa,
#sectionForm .grid .CNPJ,
#sectionForm .grid .Telefone,
#sectionForm .grid .CEP,
#sectionForm .grid .Endereco,
#sectionForm .grid .Bairro,
#sectionForm .grid .E-mail,
#sectionForm .grid .Faturamento,
#sectionForm .grid .Porte,
#sectionForm .grid .Atividade,
#sectionForm .grid .Associado,
#sectionForm .grid .Agencia {
    grid-column: 1/13;
  }
  #sectionForm .grid .Estado {
    grid-column: 1/7;
  }
  #sectionForm .grid .Cidade {
    grid-column: 7/13;
  }
  #sectionForm .grid .Porte,
#sectionForm .grid .Atividade,
#sectionForm .grid .Associado {
    align-items: center;
  }
  #sectionForm .grid .Agencia {
    text-align: center;
  }
  #sectionForm .grid .cpfSocio1 {
    grid-column: 1/13;
  }
  #sectionForm .grid .nomeSocio1 {
    grid-column: 1/13;
  }
  #sectionForm .grid .emailSocio1 {
    grid-column: 1/13;
  }
  #sectionForm .grid .telefoneSocio1 {
    grid-column: 1/7;
  }
  #sectionForm .grid .nascimentoSocio1 {
    grid-column: 7/13;
  }
  #sectionForm .grid .nomemaeSocio1 {
    grid-column: 1/13;
  }
  #sectionForm .grid .rgSocio1 {
    grid-column: 1/7;
  }
  #sectionForm .grid .sexoSocio1 {
    grid-column: 7/13;
  }
  #sectionForm .grid .naturalidadeSocio1 {
    grid-column: 1/13;
  }
  #sectionForm .grid .cepSocio1 {
    grid-column: 1/13;
  }
  #sectionForm .grid .enderecoSocio1 {
    grid-column: 1/13;
  }
  #sectionForm .grid .bairroSocio1 {
    grid-column: 1/13;
  }
  #sectionForm .grid .estadoSocio1 {
    grid-column: 1/5;
  }
  #sectionForm .grid .cidadeSocio1 {
    grid-column: 5/13;
  }
  #sectionForm .grid .sociedadeSocio1 {
    grid-column: 3/11;
  }
  #sectionForm .grid .cidadeSocio2 {
    grid-column: 5/13;
  }
  #sectionForm .grid .sociedadeSocio2 {
    grid-column: 3/11;
  }
  #sectionForm .grid .complementoSocio1 {
    grid-column: 1/13;
  }
  #sectionForm .grid .estadoCivilSocio1 {
    grid-column: 1/13;
    padding: 0 20px;
    align-items: center;
    justify-content: center;
    min-height: 150px;
  }
  #sectionForm .grid .estadoCivilSocio1 .estadoCivilRadio {
    display: flex;
    justify-content: center;
  }
  #sectionForm .grid .conjugeFields {
    grid-column: 1/13;
  }
  #sectionForm .grid .profissaoCj {
    grid-column: 1/13;
  }
  #sectionForm .grid .rendaCj {
    grid-column: 1/13;
  }
  #sectionForm .grid .valorCredito {
    grid-column: 1/13;
  }
  #sectionForm .grid .nmParcelas {
    grid-column: 1/7;
  }
  #sectionForm .grid .carencia {
    grid-column: 7/13;
  }
  #sectionForm .grid .creditoEmprego {
    display: grid;
    grid-column: 1/13;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    -moz-column-gap: 10px;
         column-gap: 10px;
    row-gap: 20px;
  }
  #sectionForm .grid .creditoEmprego .fCredito {
    grid-column: 1/13;
  }
  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito {
    width: 100%;
    padding: 20px 0 0 20px;
    min-height: 330px;
  }
  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito label {
    margin-bottom: 20px;
  }
  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito .finalidadeCreditoRadio {
    font-weight: bold;
  }
  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito .finalidadeCreditoRadio label {
    margin: 0;
  }
  #sectionForm .grid .creditoEmprego .fEmpregos {
    grid-column: 1/13;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
  #sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregados,
#sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregos {
    width: 100%;
  }
  #sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregos {
    margin-top: 20px;
  }
}
@media (min-width: 576px) {
  #sectionForm .grid {
    width: inherit;
  }
  #sectionForm .grid .Empresa {
    grid-column: 1/8;
  }
  #sectionForm .grid .CNPJ,
#sectionForm .grid .Endereco {
    grid-column: 8/13;
  }
  #sectionForm .grid .Telefone,
#sectionForm .grid .Bairro {
    grid-column: 1/5;
  }
  #sectionForm .grid .CEP {
    grid-column: 5/8;
  }
  #sectionForm .grid .Estado {
    grid-column: 5/9;
  }
  #sectionForm .grid .Cidade {
    grid-column: 9/13;
  }
  #sectionForm .grid .E-mail {
    grid-column: 1/6;
  }
  #sectionForm .grid .Faturamento {
    grid-column: 6/13;
  }
  #sectionForm .grid .Porte,
#sectionForm .grid .Atividade,
#sectionForm .grid .Associado {
    grid-column: 1/13;
    align-items: center;
  }
  #sectionForm .grid .Agencia {
    grid-column: 1/13;
    text-align: center;
  }
  #sectionForm .grid .cpfSocio1 {
    grid-column: 1/5;
  }
  #sectionForm .grid .nomeSocio1 {
    grid-column: 5/13;
  }
  #sectionForm .grid .emailSocio1 {
    grid-column: 1/9;
  }
  #sectionForm .grid .telefoneSocio1 {
    grid-column: 9/13;
  }
  #sectionForm .grid .nascimentoSocio1 {
    grid-column: 1/5;
  }
  #sectionForm .grid .nomemaeSocio1 {
    grid-column: 5/13;
  }
  #sectionForm .grid .rgSocio1 {
    grid-column: 1/5;
  }
  #sectionForm .grid .sexoSocio1 {
    grid-column: 5/8;
  }
  #sectionForm .grid .naturalidadeSocio1 {
    grid-column: 8/13;
  }
  #sectionForm .grid .cepSocio1 {
    grid-column: 1/5;
  }
  #sectionForm .grid .enderecoSocio1 {
    grid-column: 5/13;
  }
  #sectionForm .grid .bairroSocio1 {
    grid-column: 1/5;
  }
  #sectionForm .grid .estadoSocio1 {
    grid-column: 5/8;
  }
  #sectionForm .grid .cidadeSocio1 {
    grid-column: 8/13;
  }
  #sectionForm .grid .sociedadeSocio1 {
    grid-column: 4/10;
  }
  #sectionForm .grid .cidadeSocio2 {
    grid-column: 8/13;
  }
  #sectionForm .grid .sociedadeSocio2 {
    grid-column: 8/13;
  }
  #sectionForm .grid .complementoSocio1 {
    grid-column: 1/8;
  }
  #sectionForm .grid .estadoCivilSocio1 {
    grid-column: 1/13;
    padding: 0 20px;
    align-items: center;
    justify-content: center;
    min-height: 150px;
  }
  #sectionForm .grid .estadoCivilSocio1 .estadoCivilRadio {
    display: flex;
    justify-content: center;
  }
  #sectionForm .grid .conjugeFields {
    grid-column: 1/13;
  }
  #sectionForm .grid .profissaoCj {
    grid-column: 8/13;
  }
  #sectionForm .grid .rendaCj {
    grid-column: 4/10;
  }
  #sectionForm .grid .valorCredito {
    grid-column: 1/13;
  }
  #sectionForm .grid .nmParcelas {
    grid-column: 1/7;
  }
  #sectionForm .grid .carencia {
    grid-column: 7/13;
  }
  #sectionForm .grid .creditoEmprego {
    display: grid;
    grid-column: 1/13;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    -moz-column-gap: 10px;
         column-gap: 10px;
    row-gap: 20px;
  }
  #sectionForm .grid .creditoEmprego .fCredito {
    grid-column: 1/13;
  }
  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito {
    width: 100%;
    padding: 20px 0 0 20px;
    min-height: 330px;
  }
  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito label {
    margin-bottom: 20px;
  }
  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito .finalidadeCreditoRadio {
    font-weight: bold;
  }
  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito .finalidadeCreditoRadio label {
    margin: 0;
  }
  #sectionForm .grid .creditoEmprego .fEmpregos {
    grid-column: 1/13;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
  #sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregados,
#sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregos {
    width: 100%;
  }
  #sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregos {
    margin-top: 20px;
  }
}
@media (min-width: 768px) {
  #sectionForm .grid {
    width: inherit;
  }
  #sectionForm .grid .Empresa {
    grid-column: 1/7;
  }
  #sectionForm .grid .CNPJ {
    grid-column: 7/10;
  }
  #sectionForm .grid .Telefone,
#sectionForm .grid .Bairro {
    grid-column: 10/13;
  }
  #sectionForm .grid .CEP,
#sectionForm .grid .Estado {
    grid-column: 1/5;
  }
  #sectionForm .grid .Endereco {
    grid-column: 5/10;
  }
  #sectionForm .grid .Cidade {
    grid-column: 5/8;
  }
  #sectionForm .grid .E-mail {
    grid-column: 8/13;
  }
  #sectionForm .grid .Faturamento {
    grid-column: 1/7;
  }
  #sectionForm .grid .Porte,
#sectionForm .grid .Associado {
    grid-column: 7/13;
    align-items: center;
  }
  #sectionForm .grid .Atividade {
    grid-column: 1/7;
    align-items: center;
  }
  #sectionForm .grid .Agencia {
    grid-column: 2/12;
    text-align: center;
  }
  #sectionForm .grid .cpfSocio1 {
    grid-column: 1/4;
  }
  #sectionForm .grid .nomeSocio1 {
    grid-column: 4/9;
  }
  #sectionForm .grid .emailSocio1 {
    grid-column: 9/13;
  }
  #sectionForm .grid .telefoneSocio1 {
    grid-column: 1/4;
  }
  #sectionForm .grid .nascimentoSocio1 {
    grid-column: 4/7;
  }
  #sectionForm .grid .nomemaeSocio1 {
    grid-column: 7/13;
  }
  #sectionForm .grid .rgSocio1 {
    grid-column: 1/4;
  }
  #sectionForm .grid .sexoSocio1 {
    grid-column: 4/6;
  }
  #sectionForm .grid .naturalidadeSocio1 {
    grid-column: 6/9;
  }
  #sectionForm .grid .cepSocio1 {
    grid-column: 9/13;
  }
  #sectionForm .grid .enderecoSocio1 {
    grid-column: 1/7;
  }
  #sectionForm .grid .bairroSocio1 {
    grid-column: 7/11;
  }
  #sectionForm .grid .estadoSocio1 {
    grid-column: 11/13;
  }
  #sectionForm .grid .cidadeSocio1 {
    grid-column: 1/10;
  }
  #sectionForm .grid .sociedadeSocio1 {
    grid-column: 10/13;
  }
  #sectionForm .grid .cidadeSocio2 {
    grid-column: 1/5;
  }
  #sectionForm .grid .sociedadeSocio2 {
    grid-column: 10/13;
  }
  #sectionForm .grid .complementoSocio1 {
    grid-column: 5/10;
  }
  #sectionForm .grid .estadoCivilSocio1 {
    grid-column: 1/13;
    align-items: center;
    justify-content: center;
    min-height: 100px;
  }
  #sectionForm .grid .estadoCivilSocio1 .estadoCivilRadio {
    display: flex;
    justify-content: center;
  }
  #sectionForm .grid .conjugeFields {
    grid-column: 1/13;
  }
  #sectionForm .grid .profissaoCj {
    grid-column: 10/13;
  }
  #sectionForm .grid .rendaCj {
    grid-column: 4/10;
  }
  #sectionForm .grid .valorCredito {
    grid-column: 1/6;
  }
  #sectionForm .grid .nmParcelas {
    grid-column: 6/9;
  }
  #sectionForm .grid .carencia {
    grid-column: 9/13;
  }
  #sectionForm .grid .creditoEmprego {
    display: grid;
    grid-column: 1/13;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    -moz-column-gap: 10px;
         column-gap: 10px;
  }
  #sectionForm .grid .creditoEmprego .fCredito {
    grid-column: 1/13;
  }
  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito {
    width: 100%;
    padding: 20px 0 0 20px;
    min-height: 330px;
  }
  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito label {
    margin-bottom: 20px;
  }
  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito .finalidadeCreditoRadio {
    font-weight: bold;
  }
  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito .finalidadeCreditoRadio label {
    margin: 0;
  }
  #sectionForm .grid .creditoEmprego .fEmpregos {
    grid-column: 1/13;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    -moz-column-gap: 10px;
         column-gap: 10px;
    align-items: center;
    width: 100%;
  }
  #sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregados,
#sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregos {
    width: 100%;
  }
  #sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregados {
    grid-column: 1/7;
  }
  #sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregos {
    grid-column: 7/13;
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  #sectionForm .grid {
    width: inherit;
  }
  #sectionForm .grid .Empresa {
    grid-column: 1/7;
  }
  #sectionForm .grid .CNPJ,
#sectionForm .grid .Bairro {
    grid-column: 7/10;
  }
  #sectionForm .grid .CEP,
#sectionForm .grid .Cidade {
    grid-column: 1/3;
  }
  #sectionForm .grid .Endereco {
    grid-column: 3/7;
  }
  #sectionForm .grid .Estado,
#sectionForm .grid .Telefone {
    grid-column: 10/13;
  }
  #sectionForm .grid .E-mail {
    grid-column: 3/6;
  }
  #sectionForm .grid .Faturamento {
    grid-column: 6/10;
  }
  #sectionForm .grid .Porte {
    grid-column: 10/13;
    padding: 0 0 0 5px;
  }
  #sectionForm .grid .Atividade {
    grid-column: 1/6;
    align-self: center;
    padding: 5px 0;
  }
  #sectionForm .grid .Associado {
    grid-column: 6/9;
    align-self: center;
    padding: 5px 0;
  }
  #sectionForm .grid .Agencia {
    grid-column: 9/13;
  }
  #sectionForm .grid .cpfSocio1 {
    grid-column: 1/4;
  }
  #sectionForm .grid .nomeSocio1 {
    grid-column: 4/10;
  }
  #sectionForm .grid .emailSocio1 {
    grid-column: 10/13;
  }
  #sectionForm .grid .telefoneSocio1 {
    grid-column: 1/4;
  }
  #sectionForm .grid .nascimentoSocio1 {
    grid-column: 4/7;
  }
  #sectionForm .grid .nomemaeSocio1 {
    grid-column: 7/13;
  }
  #sectionForm .grid .rgSocio1 {
    grid-column: 1/3;
  }
  #sectionForm .grid .sexoSocio1 {
    grid-column: 3/4;
  }
  #sectionForm .grid .naturalidadeSocio1 {
    grid-column: 4/6;
  }
  #sectionForm .grid .cepSocio1 {
    grid-column: 6/8;
  }
  #sectionForm .grid .enderecoSocio1 {
    grid-column: 8/13;
  }
  #sectionForm .grid .bairroSocio1 {
    grid-column: 1/5;
  }
  #sectionForm .grid .estadoSocio1 {
    grid-column: 5/7;
  }
  #sectionForm .grid .cidadeSocio1 {
    grid-column: 7/10;
  }
  #sectionForm .grid .sociedadeSocio1 {
    grid-column: 10/13;
  }
  #sectionForm .grid .cidadeSocio2 {
    grid-column: 7/10;
  }
  #sectionForm .grid .sociedadeSocio2 {
    grid-column: 5/10;
  }
  #sectionForm .grid .complementoSocio1 {
    grid-column: 10/13;
  }
  #sectionForm .grid .estadoCivilSocio1 {
    grid-column: 1/13;
    align-items: center;
  }
  #sectionForm .grid .conjugeFields {
    grid-column: 1/13;
  }
  #sectionForm .grid .profissaoCj {
    grid-column: 1/7;
  }
  #sectionForm .grid .rendaCj {
    grid-column: 7/13;
  }
  #sectionForm .grid .valorCredito {
    grid-column: 1/5;
  }
  #sectionForm .grid .nmParcelas {
    grid-column: 5/8;
  }
  #sectionForm .grid .carencia {
    grid-column: 8/13;
  }
  #sectionForm .grid .creditoEmprego {
    display: grid;
    grid-column: 1/13;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    -moz-column-gap: 10px;
         column-gap: 10px;
  }
  #sectionForm .grid .creditoEmprego .fCredito {
    grid-column: 1/7;
  }
  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito {
    width: 100%;
    padding: 20px 0 0 20px;
    min-height: 330px;
  }
  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito label {
    margin-bottom: 20px;
  }
  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito .finalidadeCreditoRadio {
    font-weight: bold;
  }
  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito .finalidadeCreditoRadio label {
    margin: 0;
  }
  #sectionForm .grid .creditoEmprego .fEmpregos {
    grid-column: 7/13;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    -moz-column-gap: 10px;
         column-gap: 10px;
    row-gap: 20px;
    align-items: center;
    width: 100%;
  }
  #sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregados,
#sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregos {
    grid-column: 3/13;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  #sectionForm .grid {
    width: inherit;
  }
}
@media (min-width: 1400px) {
  #sectionForm .grid {
    width: inherit;
  }
}
#sectionForm .socioTitle {
  border-bottom: 2px solid #fff;
  color: #ffffff;
  margin: 50px 0;
  padding: 10px 0;
  width: inherit;
}
#sectionForm .conjugeTitle {
  color: #ffffff;
  margin: 50px 0;
  padding: 10px 0;
  width: inherit;
}
#sectionForm .conjugeTitle h2 {
  border-bottom: 2px solid #fff;
  padding-bottom: 10px;
  margin-bottom: 50px;
}
#sectionForm .addSocio {
  width: 100%;
  margin: 50px 0;
  padding: 15px 0;
  background-color: #46d400;
  font-size: 18px;
  color: #ffffff;
  border-radius: 25px;
}
#sectionForm .addSocio:hover {
  background-color: #3b9901;
  color: #fff;
}
#sectionForm .exclusaoSocio {
  width: 100%;
  display: flex;
  justify-content: right;
}
#sectionForm .exclusaoSocio .excluiSocio {
  margin: 0 0 25px 0;
  background-color: #C62828;
  padding: 15PX 10PX;
}
#sectionForm .exclusaoSocio .excluiSocio:hover {
  background-color: #D50000;
}

.termosAceite {
  margin: 50px 0;
}
.termosAceite .termosGroup {
  display: flex !important;
  flex-direction: column !important;
  gap: 25px !important;
  color: #fff;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}
.termosAceite .termosGroup .politicaCheck {
  display: flex;
  align-items: center;
}
.termosAceite .termosGroup .politicaCheck .checkbox svg {
  margin-right: 15px;
}
.termosAceite .termosGroup .politicaCheck p a {
  color: #46d400;
  font-weight: 500;
}
.termosAceite .checkbox {
  padding: 25px 0 25px 15px;
}
.termosAceite .checkbox svg {
  color: #fff;
  margin-right: 25px;
}
.termosAceite .popupctd {
  position: relative;
  color: #1a1a1a;
  max-width: 100%;
}
.termosAceite .popupctd h3 {
  padding-bottom: 15px;
}
.termosAceite .popupctd p {
  margin-bottom: 15px;
}
.termosAceite .docSolicita,
.termosAceite .docAnalise {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  color: #ffffff;
  font-size: 30px;
}
.termosAceite .docSolicita {
  padding: 50px 0 50px 0;
  font-weight: 400;
  color: #ffffff;
}
.termosAceite .docAnalise {
  font-weight: 500;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  z-index: 10000;
  overflow-y: scroll;
}
.popup-inner {
  position: relative;
  padding: 32px;
  height: -webkit-max-content !important;
  height: -moz-max-content !important;
  height: max-content !important;
  max-width: 1000px;
  background-color: #fff;
}
.popup-inner .close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
}/*# sourceMappingURL=App.css.map */